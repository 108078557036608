// Try to override these variables: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;800&display=swap.css');

// tmp, please remove and only used ant variables
@font: 'Manrope', Helvetica Neue, Helvetica, Arial, sans-serif;
@font-size: 13px;
@small-font: 11px;

@heading-1-size: ceil(@font-size-base * 2.71);
@heading-2-size: ceil(@font-size-base * 2.14);
@heading-3-size: ceil(@font-size-base * 1.71);
@heading-4-size: ceil(@font-size-base * 1.42);
@heading-5-size: ceil(@font-size-base * 1.14);

/*@font-size: clamp(1rem, 10vw, 2rem);*/

// COLORS
@primary-color: #C2CE26;
@action-color: #f6933d;
@action-border-color: #fabe8b;

@main-view-background-color: #6b6b6b;
@background-color-light: hsv(0,0, 98%);
@border-color: #E9E9E9;
@body-background: #6b6b6b;
@main-view-bounding-background: #f8f9fa;
@component-background: #fff;


a {
  color: #000;
  text-decoration: underline;

  &:hover {
    color: @primary-color;
  }
}


// Extra Colors

@black:black;

@blue: #85B0D6;
@light-blue: #b6d0e7;
@green: #cdcd23;
@green-rgba-100: rgba(194, 206, 38, 1);
@green-rgba-70: rgba(194, 206, 38, 0.7);
@green-rgba-50: rgba(194, 206, 38, 0.5);
@green-rgba-30: rgba(194, 206, 38, 0.3);
@green-rgba-10: rgba(194, 206, 38, 0.1);
@green-rgba-0: rgba(194, 206, 38, 0);
@gray-rgba-100: rgba(166, 166, 166, 1);
@gray-rgba-70: rgba(166, 166, 166, 0.7);
@gray-rgba-50: rgba(166, 166, 166, 0.5);
@gray-rgba-30: rgba(166, 166, 166, 0.3);
@gray-rgba-10: rgba(166, 166, 166, 0.1);
@gray-rgba-0: rgba(166, 166, 166, 0);

@blue-rgba-0: rgba(133, 176, 214, 0);
@blue-rgba-100: rgba(133, 176, 214, 1);
@blue-rgba-70: rgba(133, 176, 214, 0.7);
@blue-rgba-50: rgba(133, 176, 214, 0.5);
@blue-rgba-30: rgba(133, 176, 214, 0.3);
@blue-rgba-10: rgba(133, 176, 214, 0.1);
@blue-rgba-0: rgba(133, 176, 214, 0);

@dark-blue: #4499c1;
@dark-green: #919a1a;
@dark-green-rgba-100: rgba(145, 154, 26, 1);
@dark-green-rgba-70: rgba(145, 154, 26, 0.7);
@dark-green-rgba-50: rgba(145, 154, 26, 0.5);
@dark-green-rgba-10: rgba(145, 154, 26, 0.1);
@light-green: #e0e775;
@yellow: #ffff00;
@yellow-rgba-100: rgba(255, 255, 0, 1);
@yellow-rgba-70: rgba(255, 255, 0, 0.7);
@yellow-rgba-50: rgba(255, 255, 0, 0.5);
@yellow-rgba-10: rgba(255, 255, 0, 0.1);
@orange-rgba-100: rgba(246, 147, 61, 1);
@orange-rgba-70: rgba(246, 147, 61, 0.7);
@orange-rgba-10: rgba(246, 147, 61, 0.1);
@orange-rgba-0: rgba(246, 147, 61, 0);
@brown: #81594D;
@grey: #7F7F7F;
@light-grey: #a6a6a6;
@dark: #494949;
@red: #F6623D;
@red-rgba-100: rgba(246, 98, 61, 1);
@red-rgba-70: rgba(246, 98, 61, 0.7);
@red-rgba-50: rgba(246, 98, 61, 0.5);
@red-rgba-10: rgba(246, 98, 61, 0.1);
@cancel-red: #ef5028;
@light-red: #FF9980;
@white: #ffffff;
@background-color-grey: #f8f9fa;
@visited-color: #F2F2F2;
@sub-category-text: #BFBFBF;
@light-grey-rgb: rgba(166, 166, 166, 0.1);
@darker-grey: #58585a;
@inactive-background-color: #D3D3D3;
@opt-out-background-color:#ffa39e;
@custom-multi-select-item-border: #f0f0f0;





/*@main-view-background-color: #818285;*/



@font-family: 'Manrope', Helvetica Neue, Helvetica, Arial, sans-serif;
@font-size-base: @font-size;
@font-size-lg: @font-size-base + 2px;
@border-radius-base: 4px;
@secondary-color: #85B0D6;
@link-color: #ADB81F;
@success-color: #52c41a;
@warning-color: #faad14;
@error-color: #f5222d;
@border-color-base: #D9D9D9;
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);

@heading-color: rgba(0, 0, 0, 0.65);
@heading-color-1: rgba(0, 0, 0, 0.10);
@text-color: rgba(0, 0, 0, .65);
@text-color-secondary : rgba(0, 0, 0, .45);
@disabled-color : rgba(0, 0, 0, .25);
@text-color-secondary: rgba(0, 0, 0, .45);
@disabled-color: rgba(0, 0, 0, .25);

// vertical paddings
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small
@padding-left-detail-views: 25px; // all content in detail views needs to have a margin left of 25px because of behaviour of some components
@main-view-padding-size: 30px;

// Drawer
// ---
@drawer-body-padding: 48px 0;

// Collapse
// ---
@collapse-header-padding: @padding-sm @padding-md;
@collapse-header-padding-extra: 40px;
@collapse-header-bg: fade(#FFFFFF, (0.09*100));
@collapse-content-padding: @padding-md;
@collapse-content-bg: #F5F5F5;
@collapse-header-arrow-left: 16px;

// Dashboard
@dashboard-grid-horizontal-padding: 9px; // 9 comes from [DashboardGridView.tsx:ResponsiveGridLayout:margin[1]]

// Pagination
// ---
//@pagination-item-bg: @component-background;
//@pagination-item-size: @height-base;
@pagination-item-size-sm: 24px;
@pagination-font-family: @font-family;
@pagination-font-weight-active: 500;
//@pagination-item-bg-active: @component-background;
//@pagination-item-link-bg: @component-background;
//@pagination-item-disabled-color-active: @white;
//@pagination-item-disabled-bg-active: darken(@disabled-bg, 10%);
//@pagination-item-input-bg: @component-background;
@pagination-mini-options-size-changer-top: 0px;

// ==================================
// ========= Old Setup ==============
// ==================================

//responsive view
@min-collapse: 1080px;
@tablet-width: 1250px;
@mobile-width: 990px;
@min-desktop-width:1375px;

@media-xs: 0px;
@media-sm: 576px;
@media-md: 768px;
@media-lg: 992px;
@media-xl: 1200px;
@media-xxl: 1600px;


// Tabs
// ---
//@tabs-bar-margin: 0;

//Table



/**/
/*@table-bg: @component-background;





@table-body-sort-bg: #fafafa;
@table-row-hover-bg: @background-color-light;
@table-selected-row-color: inherit;
@table-selected-row-bg: @primary-1;
@table-body-selected-sort-bg: @table-selected-row-bg;
@table-selected-row-hover-bg: darken(@table-selected-row-bg, 2%);
@table-expanded-row-bg: #fbfbfb;
@table-padding-vertical: 16px;
@table-padding-horizontal: 16px;
@table-padding-vertical-md: @table-padding-vertical * 3 / 4;
@table-padding-horizontal-md: @table-padding-horizontal / 2;
@table-padding-vertical-sm: @table-padding-vertical / 2;
@table-padding-horizontal-sm: @table-padding-horizontal / 2;
@table-border-radius-base: @border-radius-base;
@table-footer-bg: @background-color-light;*/
/*@table-footer-color: red;*/
/*@table-header-bg-sm: @table-header-bg;
@table-font-size: @font-size-base;
@table-font-size-md: @table-font-size;
@table-font-size-sm: @table-font-size;
// Sorter
// Legacy: `table-header-sort-active-bg` is used for hover not real active
@table-header-sort-active-bg: darken(@table-header-bg, 3%);
// Filter
@table-header-filter-active-bg: darken(@table-header-sort-active-bg, 5%);
@table-filter-btns-bg: inherit;
@table-filter-dropdown-bg: @component-background;
@table-expand-icon-bg: @component-background;
@table-selection-column-width: 60px;
@table-selection-extra-right: 0;*/






// Activity Type Colors
@activity-call: #85B0D6;
@activity-canvas_call: #2291e4;
@activity-group_call: #2041e4;
@activity-visit: #C2CE26;
@activity-service_visit: #C0ac20;
@activity-double_visit: #C0ff26;
@activity-congress: #c7953d;
@activity-symposia: #cc5dd8;
@activity-workshop: #d90013;
@activity-meetings: #abffff;



// Antd



//Size
@micro: 5px;
@x-small: 10px;
@small: 20px;
@medium: 40px;
@large: 50px;
@x-large: 80px;


//TABLE
@table-header-color: @heading-color;
@table-header-bg: #F1F3F5;
//@table-bg: @white;
@table-bg: @component-background;
//@table-body-sort-bg: #fafafa;
@table-row-hover-bg: @background-color-light;
@table-selected-row-color: inherit;
@table-selected-row-bg: #e6f7ff; //@primary-1;



/*
@pagination-item-bg: @component-background;
@pagination-item-size: @height-base;
@pagination-item-size-sm: 24px;
@pagination-font-family: @font-family;
@pagination-font-weight-active: 500;
@pagination-item-bg-active: @component-background;
@pagination-item-link-bg: @component-background;
@pagination-item-disabled-color-active: @white;
@pagination-item-disabled-bg-active: darken(@disabled-bg, 10%);
@pagination-item-input-bg: @component-background;
@pagination-mini-options-size-changer-top: 0px;*/
