@import "./_buttons";
@import "./_select";
@import "./_select";
@import "./_dropdown";
@import "./_multiSelect";
@import "./_multiTypeInput";


/* ---------------------------

	Form and inputs

--------------------------- */
.checkbox-field {
  position: relative;
  width: 16px;
  height: 16px;
  background: #F5F5F5;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 16px;
    width: 16px;
  }

  .checkmark {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    background: url('../../../img/icon-checkbox-mark.svg') no-repeat center;
    margin-top: -1px;
    margin-left: -1px;
    display: none;
    pointer-events: none;
  }

  input:checked ~ .checkmark {
    display: block;
  }
}

textarea {
  width: 100%;
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
  background: #fff;
  padding: 30px;
  color: @grey;
  border-radius: 5px;
  outline: none;
}


.edit-field > input {
  //display: none;
  background: none;
  border: none;
  border-bottom: 1px dashed #BABABA;
  outline: none;

  &:active, &:hover, &:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}


/* ---------------------------

	Switch

--------------------------- */
.form-switch-container {
  height: 54px;
  margin: 8px 20px 0 20px;

  .switch {
    float: left;
    margin: 2px 5px 0 0;

    .ant-switch-checked {
      background-color: @blue !important;
    }

    .ant-switch {
      background-color: #BFBFBF;
      display: inline-block;
      width: 52px;
      height: 30px;
      overflow: hidden;
      box-shadow: none;
      outline: none;

      &:after {
        height: 26px;
        width: 26px;
        background-color: @white;
        transition: all 0.2s ease-in-out;
        border: 1px solid #bebebe;
        box-shadow: none;
        outline: none;
      }
    }
  }

  .global-search-records {
    float: left;

    p {
      float: left;
      width: 100%;
      margin-bottom: 0;
      .fontSize(14px);
    }

    small {
      float: left;
      width: 100%;
    }
  }
}

