@import "types/_criteriaMultiSelect";
@import "types/_criteriaLovModal";
@import "types/_criteriaDateOption";

.criteria-input-container {

  span.label {
    padding: 0 0 9px 12px;
    font-weight: 400;
    &::after {
      content: ':';
    }
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
      flex: 1;
      margin: 0;
      &.anticon {
        flex: 0;
        padding: 12px;
        cursor: grab;
      }
    }

    .ant-space-item {
      width: 100%;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
    .disabled {
      pointer-events: none;
    }
  }

  .ant-form-item-explain.ant-form-item-explain {
    display: none;
  }
}
.criteria-input-container.contrast-label {
  span.label {
    color: @white;
  }
}
