
.quick-action-button-container {

  .ant-btn {
    border-radius: 90px;
    color: @white;

    &.action-to-take {
      background-color: @action-color;
      &:hover, &:active, &:focus {
        border-color: @action-border-color;
      }
    }

    &.primary-action {
      background-color: @primary-color;
      &:hover, &:active, &:focus {
        border-color: @light-blue;
      }
    }

    &.old-blue {
      background-color: @blue;
      &:hover, &:active, &:focus {
        border-color: @light-blue;
      }
    }

  }
}
