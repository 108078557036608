@import "./ActivityDetails/_activityDetails";
@import "./ActivityTable/_activityNestedTable";
@import "./CreateView/_activityCreateDrawer";

.activities-container, .activity-container {

  .dropdown-chevron {
    padding: 8px;
    height: 24px;
    cursor: pointer;
  }

  .overview-view-type > ul > li {
    cursor: pointer;
  }

  .overview-view-type > ul > li.active > img {
    // TODO: We should rather use a different image for this.
    filter: invert(1);
  }

  h2{
    .fontSize(@font-size + 1);

  }


}

.contact-persons.content-section{
  margin-top: 40px;
}

.activity-modal {
  .activity-completed-icon {
    padding-bottom: 40px;
  }
}

.activity-create-container {
  .activity-create {
    margin-bottom: 10px;
  }
}

.activity-notes-container .ant-input-disabled{
  background-color: white;
  color:@text-color
}

.activity-details-container{
  .detail-content{
  //  width: 85%;
  }
}

.activity-custom-table {
  table {
    td > div.last-visited {
      border-right: 3px solid @blue;
    }
  }
}
