

.entities-search-drawer-container {
  .entity-search-drawer-container{
    margin-top: 10px;
    .ant-input-wrapper{
      margin-bottom: 20px;
    }
  }

  .ant-drawer-body {
    height: 100%;

    overflow-y: hidden;

    .tabs.is-dragging {
      visibility: hidden;
      .portal-aware-item {
        border: 3px solid red;
        visibility: hidden;
        //display: none;
      }
      * {
        //height: 20px;
      }
      //visibility: hidden;
    }
  }

  .input.APB_LIST_DETAILS{
    width: 220px;
  }

}
