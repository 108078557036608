

.simple-saved-searches-container {
  width: 100%;

  .search {
    border: none;
    border-bottom: 1px solid @border-color-base;
    border-radius: 0;
    padding: @padding-xss @padding-xs @padding-xss @padding-md;

    .ant-input-search-icon::before {
      border-left: none;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: @padding-xss @padding-xs @padding-xss @padding-md;

    &.selected {
      background-color: fade(@light-green, (.5*100));
      box-shadow: 0 0 @padding-sm 0 fade(@light-green, (.5*100));
    }

    .left {
      color: @text-color;
      opacity: 0.9;
    }

    .right {
      color: @text-color-secondary;
      opacity: 0.75;
    }
  }
}
