/* ---------------------------

	NAVIGATION

--------------------------- */

.navigation {
  float: left;
  width: 220px;
  height: calc(~"100vh - 60px");
  position: fixed;
  background-color: @white;
  top: 60px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.10);
  transition: width ease-in-out 150ms;
  .clemScrollBar(100%, 8px);

  .ant-menu-item-selected {
    background-color: @visited-color !important;
  }


  .ant-menu-item a {
    color: @grey;
    font-weight: 500;
    text-decoration: none;
  }

  .menu-icon {
    float: left;
    width: @small;
    height: @small;
    margin-right: @x-small;

   /* &.menu-home {
      background: url('../../img/icon-home-grey.svg') no-repeat center;
    }

    &.menu-persons {
      background: url('../../img/icon-persons-grey.svg') no-repeat center;
    }

    &.menu-sites {
      background: url('../../img/icon-sites-grey.svg') no-repeat center;
    }

    &.menu-deliveries {
      background: url('../../img/product_samples.svg') no-repeat center;
    }

    &.menu-activities {
      background: url('../../img/Activity_Icon.svg') no-repeat center;
    }

    &.menu-export {
      background: url('../../img/icon-export-grey.svg') no-repeat center;
    }

    &.menu-import {
      background: url('../../img/icon-import.svg') no-repeat center;
    }

    &.menu-settings {
      background: url('../../img/icon-settings-grey.svg') no-repeat center;
    }

    &.menu-list {
      background: url('../../img/icon-list-grey.svg') no-repeat center;
    }*/
  }

  &.collapse {
    width: 80px;

    .ant-menu-item-selected {
      border-right: 2px solid;
    }

  }

  .ant-collapse-content-box {
    border: none !important;
    padding: 0 !important;
  }

  .ant-collapse-item-active {
    border-bottom: none;
  }

  .ant-collapse-content {
    border: none;

  }

  .ant-collapse > .ant-collapse-item {
    border: none;
  }

  .ant-collapse-header {
    color: @light-grey !important;
    font-size: @small-font;
    background-color: @white;
    border-bottom: none !important;
    display: block;
    height: 45px;
    min-height: 45px;
    padding-bottom: 0px;
  }

  .panel-collapse {
    .ant-collapse-arrow {
      left: 15px !important;
    }
  }

  .ant-menu-item-only-child {
    //align-self: center;
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }
  }

  .ant-menu-item.ant-menu-item-only-child:last-child {
    margin-bottom: 20px;
  }

  .collapse-button-container {
    background-color: @white;
    padding-top: 40px;
    padding-bottom: 5px;

    &.collapse {
      text-align: center;
    }

    &.open {
      padding-left: 15px;
    }

    .toggle-collapse {
      cursor: pointer;
      font-size: @small-font;
      color: @primary-color;
      display: block;
      height: 30px;
      min-height: 30px;
    }

  }

  .displayCarousel {
    .apb-icon {
      height: 20px;
      font-size: 20px;
    }
  }

  .ant-tabs-nav-list {
    margin-left: 20px !important;
  }


  .ant-carousel .slick-dots li.slick-active button {
    height: 10px;
    width: 10px;
  }

  .ant-carousel .slick-dots li button {
    height: 10px;
    width: 10px;
  }


  ul {
    background-color: white !important;
  }

  // COLLAPSED and collapse on mobile

  &.collapsed {
    width: 80px;

    nav, .fav-lists {
      .menu-section-title {
        display: none;
      }
    }
  }
}

.ddl-menu-label {
  padding-left: 10px;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.carousel-navigation {
  width: 220px !important;
}

.ant-menu-item-only-child a {
  width: 100%;
}


.navigation-container .ant-popover-inner {
    background-color: @orange-rgba-100!important;
  }
.nav-create{
  width: 100%;
  display: flex;
  align-items: center;
}
.sub-navigation{
  margin-bottom: 10px;

  .icon-container{
    margin-right: 10px;
  }
  .menu-label{
    padding-left: 5px;
  }

}
.ant-popover-content{
  .popover-close{
    cursor: pointer;
  }
}


.ant-collapse-content {
  .ant-collapse-content-box {
    padding: 24px;
  }
}

.navigation_menu.secondary{
  background-color: red;
  .popover-create{
    display: none;
    visibility: hidden;
  }
}

.icon-container.Overview{
 font-size: 30px;
}
